import { useState, useEffect } from "react";
import styled from "styled-components";
import { RouterProvider } from "react-router-dom";

import "./App.css";

import router from "./pages";

import { sendAmplitudeEvent } from "./utils/amplitude";

function App() {
  const [showMethods, setShowMethods] = useState(false);
  const [showDemographics, setShowDemographics] = useState(false);
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showAllData, setShowAllData] = useState("all");

  const setLanguageHelper = (newLang) => {
    setLanguage(newLang);
    localStorage.setItem("sumups-lang", newLang);
    window.location.reload();
  };

  const setShowAlldataHelper = (newStatus) => {
    setShowAllData(newStatus);
    localStorage.setItem("sumups-all-data", JSON.stringify(newStatus));
    window.location.reload(false);
  };

  useEffect(() => {
    sendAmplitudeEvent("App Loaded");
    if (localStorage.getItem("sumups-lang")) {
      if (localStorage.getItem("sumups-lang") === "fr") {
        setLanguage("fr");
      }
    }

    if (localStorage.getItem("sumups-all-data")) {
      console.log(localStorage.getItem("sumups-all-data"));
      setShowAllData(JSON.parse(localStorage.getItem("sumups-all-data")));
    }
  });

  return (
    <Main>
      <TopRow>
        <a href="https://ourcare.ca">
          {language === "en" ? (
            <OCLogo src={require("./assets/ourcare-logo.png")} />
          ) : (
            <OCLogo src={require("./assets/ourcare-logo-fr.png")} />
          )}
        </a>
        <Right>
          <LangToggle>
            <span
              onClick={() =>
                language === "fr" ? setLanguageHelper("en") : null
              }
              className={language === "fr" ? "" : "active"}
            >
              EN
            </span>{" "}
            /{" "}
            <span
              onClick={() =>
                language === "en" ? setLanguageHelper("fr") : null
              }
              className={language === "en" ? "" : "active"}
            >
              FR
            </span>
          </LangToggle>
          <P1>
            {language === "en"
              ? "Survey Data Explorer"
              : "Explorateur de données d'enquête"}
          </P1>
        </Right>
      </TopRow>

      <Col>
        {/* <P2> */}
        {language === "en" ? (
          <P2>
            OurCare is a pan-Canadian project to gather input from the public on
            how to rethink the future of primary care–the type of care usually
            delivered by family doctors and nurse practitioners (NPs). The
            survey was online from September 20th to October 25th, 2022. Over
            9000 people in Canada completed the full survey, sharing their
            perspectives and experience. You can view the data below.
          </P2>
        ) : (
          <P2>
            NotreSoins est un projet pancanadien visant à recueillir les
            commentaires du public sur les points suivants comment repenser
            l'avenir des soins primaires - le type de soins habituellement
            dispensés par les médecins de famille et les infirmières
            praticiennes (IP). généralement dispensés par les médecins de
            famille et les infirmières praticiennes (IP). L'enquête était en
            ligne du 20 septembre au 25 octobre 2022. Plus de 9000 personnes
            9000 personnes au Canada ont répondu à l'enquête complète,
            partageant leurs points de vue et leur expérience. Vous pouvez
            consulter les données ci-dessous.
          </P2>
        )}

        {/* </P2> */}
        {/* <P3>How to use this tool?</P3> */}

        <Btns>
          <Btn
            selected={showAllData == "all"}
            onClick={() => setShowAlldataHelper("all")}
          >
            {language === "en" ? "All Data" : "Toutes les données"}
          </Btn>
          <Btn
            selected={showAllData == "quebec"}
            onClick={() => setShowAlldataHelper("quebec")}
          >
            {language === "en" ? "Québec Data" : "Données du Québec"}
          </Btn>
          <Btn
            selected={showAllData == "ontario"}
            onClick={() => setShowAlldataHelper("ontario")}
          >
            {language === "en" ? "Ontario Data" : "Données du Ontario"}
          </Btn>
        </Btns>

        {language === "en" ? (
          <BottomItemToggle onClick={() => setShowHowItWorks(!showHowItWorks)}>
            {showHowItWorks ? "Close" : "Click here to learn about"} how to use
            this tool
          </BottomItemToggle>
        ) : (
          <BottomItemToggle onClick={() => setShowHowItWorks(!showHowItWorks)}>
            {showHowItWorks
              ? "Fermer cette section"
              : "Cliquez ici pour apprendre à utiliser cet outil"}
          </BottomItemToggle>
        )}

        {showHowItWorks && (
          <>
            {language === "en" ? (
              <>
                <BottomItemText>
                  Start by selecting one of the tabs at the top ("All
                  questions", "Those with a family doctor or NP", etc.). Then
                  you can select specific questions within each section. The
                  selected question will be the first chart you see. Below that
                  you will see a set of graphs that show how different groups of
                  people responded to the same question.
                </BottomItemText>
                <BottomItemText>
                  Move your cursor over the graph to see the exact percentages.
                  {/* as well as the total number who selected that response. */}
                </BottomItemText>
                {/* TODO: Bring this back later if need be */}
                <BottomItemText>
                  In this tool, we present weighted data. Weighting helps ensure
                  the data presented reflect a pool of respondents who roughly
                  match the demographics of people living in Canada. You can see
                  the breakdown of who responded before and after weighting
                  using the last two tabs.
                </BottomItemText>
                <BottomItemText>
                  The “N” in the title of the first graph provides the total
                  number of respondents to the question before weighting was
                  applied. When weighting is used, the effective sample size is
                  lower.
                </BottomItemText>
              </>
            ) : (
              <>
                <BottomItemText>
                  Commencez par sélectionner l'un des onglets en haut ("Toutes
                  les questions", "Ceux qui ont un médecin de famille ou une
                  infirmière", etc.) Ensuite, vous pouvez sélectionner des
                  questions spécifiques dans chaque section. La question
                  sélectionnée sera sera le premier tableau que vous verrez. En
                  dessous, vous verrez une série de qui montrent comment
                  différents groupes de personnes ont répondu à la même
                  question. même question.
                </BottomItemText>
                <BottomItemText>
                  Déplacez votre curseur sur le graphique pour voir les
                  pourcentages exacts.
                </BottomItemText>
                <BottomItemText>
                  Dans cet outil, nous présentons des données pondérées. La
                  pondération permet de s'assurer que les données présentées
                  reflètent un groupe de répondants qui correspondent à peu près
                  aux caractéristiques démographiques des personnes vivant au
                  Canada. Vous pouvez voir la répartition des répondants avant
                  et après la pondération en utilisant les deux derniers
                  onglets.
                </BottomItemText>
                <BottomItemText>
                  Le "N" dans le titre du premier graphique indique le nombre
                  total de répondants à la question avant l'application de la
                  pondération. Lorsque la pondération est utilisée, la taille
                  effective de l'échantillon est plus faible.
                </BottomItemText>
              </>
            )}

            <br />
            <hr />
          </>
        )}
      </Col>

      <Container>
        <RouterProvider router={router} />
      </Container>

      <Col>
        {language === "en" ? (
          <BtmAcknowledgementText>
            The OurCare project is led by Dr. Tara Kiran at MAP Centre for Urban
            Health Solutions, Unity Health Toronto. Learn more at{" "}
            <a href="https://ourcare.ca">OurCare.ca</a>.
          </BtmAcknowledgementText>
        ) : (
          <BtmAcknowledgementText>
            Le projet OurCare est dirigé par le Dr Tara Kiran du MAP Centre for
            Urban Health Solutions, Unity Health Toronto. Pour en savoir plus,
            consultez le site <a href="https://ourcare.ca">OurCare.ca</a>.
          </BtmAcknowledgementText>
        )}

        {language === "en" ? (
          <BottomItemToggle onClick={() => setShowMethods(!showMethods)}>
            {showMethods ? "Close" : "Click here to read about"} Survey Methods
          </BottomItemToggle>
        ) : (
          <BottomItemToggle onClick={() => setShowMethods(!showMethods)}>
            {showMethods
              ? "Méthodes d'enquête de proximité"
              : "Cliquez ici pour en savoir plus sur les méthodes d'enquête"}
          </BottomItemToggle>
        )}

        {showMethods &&
          (language === "en" ? (
            <>
              <BottomItemText>
                The OurCare survey was conducted in partnership with VoxPop
                Labs. The survey,available in English and French, was
                distributed in two ways. An anonymous link was distributed
                widely and promoted through our partner networks, traditional
                media and social media. In addition,{" "}
                <a href="https://voxpoplabs.com/">VoxPop Labs</a> sent a unique
                link to 63552 people who are part of their panel and followed up
                with two personalized reminders.
              </BottomItemText>
              <BottomItemText>
                Survey responses from the two links were combined and weighted
                via iterative proportional fitting (raking) according to latest
                Statistics Canada Census estimates. Weighting helps ensure the
                data presented reflect a pool of respondents who roughly match
                the demographics of people living in Canada. We used the
                following attributes for weighting: age, gender, education,
                income, language and region. We analyzed data only for completed
                surveys (i.e. respondents reached the end of the survey;
                respondents may have skipped questions but needed to provide
                usable responses to the demographic questions used for
                weighting).
              </BottomItemText>
              <BottomItemText>
                We received 7213 responses from the VoxPop Labs panel with 5969
                completed surveys. We received 6805 responses from the anonymous
                link with 3310 completed surveys. In total, we analyzed data for
                9279 completed responses, 73% in English and 27% in French.
              </BottomItemText>
              <br />
              <hr />
            </>
          ) : (
            <>
              <BottomItemText>
                Le sondage OurCare a été réalisé en partenariat avec VoxPop
                Labs. L'enquête, disponible en anglais et en français, a été
                distribuée de deux manières. Un lien anonyme a été largement
                diffusé et promu par nos réseaux de partenaires, les médias
                traditionnels et les médias sociaux. En outre,{" "}
                <a href="https://voxpoplabs.com/">VoxPop Labs</a> a envoyé un
                lien unique à 63552 personnes faisant partie de son panel et a
                effectué un suivi avec deux rappels personnalisés.
              </BottomItemText>
              <BottomItemText>
                Les réponses à l'enquête provenant des deux liens ont été
                combinées et pondérées par ajustement proportionnel itératif
                (raking) selon les dernières estimations du recensement de
                Statistique Canada. La pondération permet de s'assurer que les
                données présentées reflètent un ensemble de répondants qui
                correspondent approximativement aux caractéristiques
                démographiques des personnes vivant au Canada. Nous avons
                utilisé les attributs suivants pour la pondération : âge, sexe,
                éducation, revenu, langue et région. Nous avons analysé les
                données uniquement pour les sondages complétés (c'est-à-dire que
                les répondants ont atteint la fin du sondage ; les répondants
                peuvent avoir sauté des questions mais devaient fournir des
                réponses utilisables aux questions démographiques utilisées pour
                la pondération).
              </BottomItemText>
              <BottomItemText>
                Nous avons reçu 7213 réponses du panel VoxPop Labs avec 5969
                enquêtes complétées. Nous avons reçu 6805 réponses du lien
                anonyme avec 3310 enquêtes complétées. Au total, nous avons
                analysé les données de 9279 réponses complétées, 73% en anglais
                et 27% en français.
              </BottomItemText>
              <br />
              <hr />
            </>
          ))}

        {language === "en" ? (
          <BottomItemToggle
            onClick={() => setShowDemographics(!showDemographics)}
          >
            {showDemographics
              ? "Close Demographic definitions"
              : "Demographic definitions"}
          </BottomItemToggle>
        ) : (
          <BottomItemToggle
            onClick={() => setShowDemographics(!showDemographics)}
          >
            {showDemographics
              ? "Fermer Définitions démographiques"
              : "Définitions démographiques"}
          </BottomItemToggle>
        )}

        {showDemographics &&
          (language === "en" ? (
            <>
              <BottomItemText>
                <b>Gender</b>
              </BottomItemText>
              <BottomItemText>
                “Diverse” is inclusive of the following options: Transgender
                woman, Transgender man, Non-binary, and Two-spirit.
              </BottomItemText>

              <BottomItemText>
                <b>Income</b>
              </BottomItemText>
              <BottomItemText>
                Income inquired about the respondent’s personal income, not
                family or household income.
              </BottomItemText>

              <BottomItemText>
                <b>Region</b>
              </BottomItemText>
              <BottomItemText>
                “Prairies” denotes Manitoba, Saskatchewan, and Alberta.
                “Atlantic” denotes Newfoundland and Labrador, Nova Scotia,
                Prince Edward Island, and New Brunswick. We did not have any
                complete survey respondents from residents in the Territories.
              </BottomItemText>

              <BottomItemText>
                <b>Race</b>
              </BottomItemText>
              <BottomItemText>
                “Racialized” denotes any selection other than “White (European
                descent).” “Non-racialized” is exclusively those who selected
                “White (European descent).”
              </BottomItemText>

              <BottomItemText>
                <b>Rurality</b>
              </BottomItemText>
              <BottomItemText>Rurality is self-reported.</BottomItemText>

              <BottomItemText>
                <b>Has some additional health benefits</b>
              </BottomItemText>
              <BottomItemText>
                Respondents were counted as “Yes” if they reported having even
                partial insurance coverage or benefits. This included private
                insurance, Non-Insured Health Benefits, or other health benefits
                (eg. from social assistance) that paid for prescription
                medications, psychotherapy, other health professional services
                (e.g. physiotherapy) or dental care.
              </BottomItemText>
              <br />
              <hr />
            </>
          ) : (
            <>
              <BottomItemText>
                <b>Genre</b>
              </BottomItemText>
              <BottomItemText>
                Le terme "divers" englobe les options suivantes : femme
                transgenre, homme transgenre, non-binaire et bi-spirituel.
              </BottomItemText>

              <BottomItemText>
                <b>Revenu</b>
              </BottomItemText>
              <BottomItemText>
                Le revenu concerne le revenu personnel du répondant, et non le
                revenu de la famille ou du ménage.
              </BottomItemText>

              <BottomItemText>
                <b>Région</b>
              </BottomItemText>
              <BottomItemText>
                "Prairies" désigne le Manitoba, la Saskatchewan et l'Alberta.
                "Atlantique " désigne Terre-Neuve-et-Labrador, la
                Nouvelle-Écosse, l'Île-du-Prince-Édouard et le
                Nouveau-Brunswick. Nous n'avons pas eu de répondants complets à
                l'enquête de la part des résidents des Territoires.
              </BottomItemText>

              <BottomItemText>
                <b>Race</b>
              </BottomItemText>
              <BottomItemText>
                "Racialisé" désigne toute sélection autre que "Blanc (ascendance
                européenne)". "Non-racialisé" désigne exclusivement ceux qui ont
                choisi "Blanc (ascendance européenne)".
              </BottomItemText>

              <BottomItemText>
                <b>Ruralité</b>
              </BottomItemText>
              <BottomItemText>La ruralité est auto-déclarée.</BottomItemText>

              <BottomItemText>
                <b>présente des avantages supplémentaires pour la santé</b>
              </BottomItemText>
              <BottomItemText>
                Les répondants ont été comptés comme "Oui" s'ils ont déclaré
                avoir une couverture d'assurance ou des prestations, même
                partielles. Cela comprenait les assurances privées, les services
                de santé non assurés ou d'autres prestations de santé (par
                exemple, de l'aide sociale) qui payaient les médicaments sur
                ordonnance, la psychothérapie, d'autres services professionnels
                de santé (par exemple, la physiothérapie) ou les soins
                dentaires.
              </BottomItemText>

              <br />
              <hr />
            </>
          ))}

        {language === "en" ? (
          <BottomItemText>
            You can view the full survey that respondents took in{" "}
            <a
              href="https://drive.google.com/file/d/1TgKTQE18gBpbdZ-AD72JDYD_yUVVOZmj/view?usp=sharing"
              target="_blank"
            >
              English
            </a>{" "}
            or{" "}
            <a
              href="https://drive.google.com/file/d/1BNmXPEyZHWq6VE0vUdOTVmv1Gc4CgB13/view?usp=sharing"
              target="_blank"
            >
              French
            </a>
            .
          </BottomItemText>
        ) : (
          <BottomItemText>
            Vous pouvez consulter l'intégralité de l'enquête à laquelle les
            répondants ont répondu en{" "}
            <a
              href="https://drive.google.com/file/d/1TgKTQE18gBpbdZ-AD72JDYD_yUVVOZmj/view?usp=sharing"
              target="_blank"
            >
              anglais
            </a>{" "}
            ou en{" "}
            <a
              href="https://drive.google.com/file/d/1BNmXPEyZHWq6VE0vUdOTVmv1Gc4CgB13/view?usp=sharing"
              target="_blank"
            >
              français
            </a>
            .
          </BottomItemText>
        )}
      </Col>
    </Main>
  );
}

export default App;

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 996px;
  background-color: #a9a0ff;
  padding: 20px;
  border-radius: 5px;
`;

const Main = styled.div`
  background-color: #6658fb;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 30px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  max-width: 996px;
  padding-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const OCLogo = styled.img`
  width: 200px;
`;

const Right = styled.div`
  display: flex;
`;

const LangToggle = styled.p`
  font-size: 14px;
  color: white;
  margin-right: 20px;
  margin-top: 3px;

  @media (max-width: 600px) {
    font-size: 18px;
    margin-top: 10px;
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  .active {
    font-weight: 600;
    cursor: initial;
    text-decoration: none;
  }
`;

const P1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: white;

  @media (max-width: 600px) {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
  }
`;

const Col = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 996px;
  margin-bottom: 40px;
`;

const P2 = styled.p`
  font-size: 17px;
  color: white;
  margin-bottom: 20px;
`;

const P3 = styled.p`
  font-size: 14px;
  color: white;
  text-decoration: underline;
`;

const BtmAcknowledgementText = styled.p`
  font-size: 20px;
  color: white;
  margin-top: 20px;

  a {
    text-decoration: underline;
  }
`;

const BottomItemToggle = styled.p`
  margin-top: 30px;
  font-size: 20px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

const BottomItemText = styled.p`
  font-size: 17px;
  color: white;
  margin-top: 20px;

  a {
    text-decoration: underline;
  }
`;

const Btns = styled.div`
  display: row;
`;

const Btn = styled.button`
  margin-right: 10px;
  background-color: ${({ selected }) => (selected ? "white" : "transparent")};
  border: 1px solid white;
  color: ${({ selected }) => (selected ? "#6658fb" : "white")};
  padding: 5px 10px;
  curosr: pointer;
  border-radius: 3px;
`;
