import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Select, { components } from "react-select";

import Chart from "../components/Chart";

import { BACK_COLORS, DEMOCHARTLABELS, NO_PRIMARY_CARE_QUESTIONS } from "../utils/constants";
import { isArray } from "lodash";

function ChartBoard({ allMainData, mainSubData, allDemoData, totalWeight, title, multi, questionName }) {
  const [language, setLanguage] = useState("en");
  const [category, setCategory] = useState();

  const processData = (data, type, mainSubData) => {

    let notQuebec = "all";
    if (localStorage.getItem("sumups-all-data")) {
      notQuebec = JSON.parse(localStorage.getItem("sumups-all-data"));
    }

    setCategory(notQuebec);

    if (notQuebec !== "all" && !Array.isArray(data)) {
      const labels = data.lower_ci.map((item) => item[0]);
      const subLabels = data.lower_ci.map((item, index) => {
        const lower = Math.round(item[1]);
        const upper = Math.round(data.upper_ci[index][1]);
        return `95% CI: (${lower}, ${upper})`;
      });
      let values = category === "quebec" ? data.mean.map((item) => item[1]): data.mean.map((item) => item[1] / 100);
      const optionWeights = null;

      return {
        labels,
        values,
        subLabels,
        optionWeights,
      };
    }

    const labels =
      type === "main" ? data.map((d) => d[0]) : data.map((d) => d.key);
    let values =
      type === "main" ? data.map((d) => d[1]) : data.map((d) => multi? d.x_per : d.value);
    const optionWeights =
      type === "main" ? null : data.map((d) => d.option_weight);
    const lower_ci = type === "main" ? null : data.map((d) => d.lower_ci);
    const upper_ci = type === "main" ? null : data.map((d) => d.upper_ci);
    let result = [];
    if (lower_ci && upper_ci) {
      result = lower_ci.map((innerArray, outerIndex) => {
        if (isArray(innerArray)) {
          return innerArray.map((item, innerIndex) => {
            const lower = Math.round(item[1]);
            const upper = Math.round(upper_ci[outerIndex][innerIndex][1]);
            return [item[0], `(95% CI: ${lower}%, ${upper}%)`];
          });
        }
        return null;
      });
    }
    if (mainSubData) {
      result = mainSubData.lower_ci.map((item, index) => {
        const lower = Math.round(item[1]);
        const upper = Math.round(mainSubData.upper_ci[index][1]);
        return [item[0], `(95% CI: ${lower}%, ${upper}%)`];
      });
      
    }
    const subLabels = result;

    return {
      labels,
      subLabels,
      values,
      optionWeights,
    };
  };

  const { labels: mainLabels, values: mainValues, subLabels: sLabels } = useMemo(() => {
    return processData(allMainData || [], "main", mainSubData);
  }, [allMainData]);

  const multiSelectOptions = useMemo(() => {
    return mainLabels.map((ml) => {
      return { value: ml, label: ml };
    });
  }, [mainLabels]);

  const [selectedOption, setSelectedOption] = useState(multiSelectOptions[0]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (localStorage.getItem("sumups-lang")) {
      if (localStorage.getItem("sumups-lang") === "fr") {
        setLanguage("fr");
      }
    }
    setSelectedOption(multiSelectOptions[0]);
  }, [multiSelectOptions]);

  const charts = useMemo(() => {
    if(NO_PRIMARY_CARE_QUESTIONS.includes(questionName)) {
      delete DEMOCHARTLABELS.Q2;
    }
    return Object.keys(DEMOCHARTLABELS)
      .filter((dl) => !!allDemoData[dl])
      .map((dl) => {
        const { label, shape, label_fr } = DEMOCHARTLABELS[dl];
        const { labels, values, optionWeights, subLabels } = processData(
          allDemoData[dl],
          "demo"
        );

        return (
          <div key={dl} className="bg-indigo-custom rounded pb-3 h-96">
            <Chart
              name={dl}
              title={language === "en" ? label : label_fr}
              labels={labels}
              mainLabels={mainLabels}
              subLabels={subLabels}
              category=""
              values={values}
              totalWeight={totalWeight}
              optionWeights={optionWeights}
              option={selectedOption?.value}
              shape={shape}
              type={multi ? "multichart" : "demochart"}
              backgroundColors={multi ? BACK_COLORS[0] : BACK_COLORS}
            />
          </div>
        );
      });
  }, [allDemoData, mainLabels, selectedOption]);

  return (
    <>
      {allMainData && Object.keys(allMainData).length > 0 && (
        <div className="max-w-7xl pt-6 m-0">
          <div className="bg-indigo-custom pb-8 rounded justify-center">
            <div className="w-full">
              <div className="text-center text-xl pt-4 text-white">
                {title.main}
              </div>
              {title.sub && (
                <div className="text-center text-base pt-4 text-white">
                  {title.sub}
                </div>
              )}
            </div>
            <div className={"w-full " + (multi ? "h-128" : "h-96")}>
              <Chart
                name="main"
                labels={mainLabels}
                values={mainValues}
                subLabels={sLabels}
                totalWeight={totalWeight}
                type="main"
                subType={multi ? "multi" : "general"}
                shape="bar"
                backgroundColors={BACK_COLORS}
                category={category}
              />
            </div>
          </div>

          {Object.keys(allDemoData).length > 0 && multi ? (
            language === "en" ? (
              <>
                <P2>
                  Use the dropdown to see how responses differ by type of
                  respondent
                </P2>
                <P3>View the percentage of respondents who selected….</P3>
                <Select
                  options={multiSelectOptions}
                  value={selectedOption}
                  onChange={handleOptionChange}
                />
              </>
            ) : (
              <>
                <P2>
                  Utilisez la liste déroulante pour voir comment les réponses
                  diffèrent selon le type de répondant.
                </P2>
                <P3>Voir le pourcentage de répondants qui ont choisi...</P3>
                <Select
                  options={multiSelectOptions}
                  value={selectedOption}
                  onChange={handleOptionChange}
                />
              </>
            )
          ) : Object.keys(allDemoData).length > 0 && language === "en" ? (
            <>
              <P2>How did different groups respond?</P2>
              <P3>
                You can click on the response options in the graph legend to
                remove them from the display. For example, if you only want to
                see those who “strongly agree” you can click off the other
                options to focus on the percentage of people who chose this
                response.
              </P3>
              <P3>
                Move your cursor over the graph to see the exact percentages.
                {/* as well as the total number who selected that response. Please keep
                in mind that there is uncertainty in estimates when comparing
                groups, especially when the numbers in a specific group are
                small. */}
              </P3>
            </>
          ) : Object.keys(allDemoData).length > 0 && (
            <>
              <P2>Comment les différents groupes ont-ils réagi ?</P2>
              <P3>
                Vous pouvez cliquer sur les options de réponse dans la légende
                du graphique pour les supprimer de l'affichage. Par exemple, si
                vous ne voulez voir que les personnes qui sont "tout à fait
                d'accord", vous pouvez cliquer sur les autres options pour vous
                concentrer sur le pourcentage de personnes qui ont choisi cette
                réponse.
              </P3>
              <P3>
                Déplacez votre curseur sur le graphique pour voir les
                pourcentages exacts.
              </P3>
            </>
          )}

          {Object.keys(allDemoData).length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-15 gap-4 mt-5">
              {charts}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ChartBoard;

const P2 = styled.p`
  font-size: 22px;
  color: white;
  margin: 20px 0;
`;

const P3 = styled.p`
  font-size: 18px;
  color: white;
  margin: 10px 0;
`;
