export const DEMOCHARTLABELS = {
  gender_weight: {
    type: "doughnut",
    label: "Gender",
    label_fr: "Genre",
  },
  age_weight: {
    type: "bar",
    label: "Age",
    label_fr: "Âge",
  },
  education_weight: {
    type: "bar",
    label: "Education",
    label_fr: "Éducation",
  },
  income_weight: {
    type: "bar",
    label: "Income",
    label_fr: "Revenu",
  },
  region_weight: {
    type: "bar",
    label: "Region",
    label_fr: "Revenu",
  },
  language_weight: {
    type: "bar",
    label: "Language",
    label_fr: "Langue",
  },

  Q150: {
    type: "bar",
    label: "Race",
    label_fr: "Race",
  },
  canada_born_split: {
    type: "bar",
    label: "Born In Canada",
    label_fr: "Né au Canada",
  },
  "Q143-residence": {
    type: "bar",
    label: "Rurality",
    label_fr: "Ruralité",
  },
  Q155: {
    type: "bar",
    label: "Self-reported health",
    label_fr: "Santé auto-déclarée",
  },
  Q157: {
    type: "bar",
    label: "Disability",
    label_fr: "Handicap",
  },
  Q158: {
    type: "bar",
    label: "Has some additional health benefits",
    label_fr: "A des avantages supplémentaires pour la santé",
  },
  Q2: {
    type: "bar",
    label: "Has a primary care clinician",
    label_fr: "A un clinicien de soins primaires",
  },
};


export const QUEBEC_DEMOCHARTLABELS = {
  "Gender": {
    type: "doughnut",
    label: "Gender",
    label_fr: "Genre",
  },
  "Age": {
    type: "bar",
    label: "Age",
    label_fr: "Âge",
  },
  "Education": {
    type: "bar",
    label: "Education",
    label_fr: "Éducation",
  },
  "Income": {
    type: "bar",
    label: "Income",
    label_fr: "Revenu",
  },
  "Health benefit": {
    type: "bar",
    label: "Has some additional health benefits",
    label_fr: "A des avantages supplémentaires pour la santé",
  },
  "Language": {
    type: "bar",
    label: "Language",
    label_fr: "Langue",
  },
  "Rurality": {
    type: "bar",
    label: "Rurality",
    label_fr: "Ruralité",
  },
  "Born in Canada": {
    type: "bar",
    label: "Born In Canada",
    label_fr: "Né au Canada",
  },
  "Race": {
    type: "bar",
    label: "Race",
    label_fr: "Course",
  },
  "Disability": {
    type: "bar",
    label: "Disability",
    label_fr: "Invalidité",
  },
  "Self-reported health": {
    type: "bar",
    label: "Self reported health",
    label_fr: "État de santé autodéclaré",
  },
};

export const COLOR_PATTERNS = [
  ["plus", "#6df2b9"],
  ["square", "#01dbff"],
  ["circle", "#6df2b9"],
  ["diamond", "#01dbff"],
  ["triangle", "#6df2b9"],
  ["box", "#6df2b9"],
  ["cross", "#01dbff"],
  ["dash", "#6df2b9"],
  ["dot", "#01dbff"],
  ["disc", "#6df2b9"],
  ["ring", "#01dbff"],
  ["line", "#6df2b9"],
  ["weave", "#01dbff"],
  ["diagonal", "#6df2b9"],
];

export const BACK_COLORS = [
  "#6DF2B9",
  "#3456DE",
  "#01DBFF",
  "#BCF4FF",
  "#BABABB",
  "#7B8486",
  "#EFEA5A",
  "#F58F29",
  "#D5479D",
  "#A9A0FF",
  // "#6660FF",
  "#E9D6EC",
  "#FFFFFF",
  "#000000",
];

export const MULTI_SELECT_QUESTIONS = [
  "Q8",
  "Q9",
  "Q16",
  "Q17",
  "Q19",
  "Q20",
  "Q22",
  "Q32",
  "Q33",
  "Q123",
  "Q125",
  "Q126",
  "Q127",
  "Q128",
  "Q175",
  "Q131",
  "Q135",
];

export const NO_PRIMARY_CARE_QUESTIONS = [
  "Q8",
  "Q9",
  "Q16"
];
