import { useEffect, useMemo, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import styled from "styled-components";
import Select, { components } from "react-select";

import Spiner from "../components/Spiner";

import { fetchPOST } from "../utils/api";
import allQuestions from "../questions";
import QuebecQuestions from "../quebec_questions";
import OntarioQuestions from "../ontario_questions";

import ChartBoard from "./ChartBoard";

import { MULTI_SELECT_QUESTIONS } from "../utils/constants";

function QuestionDashboard({ categories }) {
  //API-1
  const [allData, setAllData] = useState();
  const [questionName, setQuestionName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [title, setTitle] = useState({});
  const [swalProps, setSwalProps] = useState({});
  let [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("en");

  const handleQuestionChange = (selectedQuestion) => {
    setSelectedOption(selectedQuestion.value);
    setQuestionName(selectedQuestion.value);

    let localLanguage = "en";

    if (localStorage.getItem("sumups-lang")) {
      if (localStorage.getItem("sumups-lang") === "fr") {
        localLanguage = "fr";
      }
    }
    console.log("Checker: ", localLanguage);

    let mainTitle = "";
    if (selectedQuestion.graph_title) {
      mainTitle =
        localLanguage === "en"
          ? selectedQuestion.graph_title
          : selectedQuestion.graph_title_fr;
    } else if (selectedQuestion.updated_label) {
      mainTitle =
        localLanguage === "en"
          ? selectedQuestion.updated_label
          : selectedQuestion.updated_label_fr;
    } else {
      mainTitle =
        localLanguage === "en"
          ? selectedQuestion.label
          : selectedQuestion.label_fr;
    }
    setTitle({
      main: mainTitle,
      sub:
        localLanguage === "en"
          ? selectedQuestion.chart_subtitle
          : selectedQuestion.chart_subtitle_fr,
    });
  };

  useEffect(() => {
    let localLanguage = "en";
    if (localStorage.getItem("sumups-lang")) {
      if (localStorage.getItem("sumups-lang") === "fr") {
        setLanguage("fr");
        localLanguage = "fr";
      }
    }
    let notQuebec = true;
    if (localStorage.getItem("sumups-all-data")) {
      notQuebec = JSON.parse(localStorage.getItem("sumups-all-data"));
    }
    let questions = []
    if (notQuebec === "all") {
      questions = allQuestions;
    } else if(notQuebec === "quebec") {
      questions = QuebecQuestions;
    } else {
      questions = OntarioQuestions;
    }
    const filteredQuestions = questions.filter((q) =>
      categories
        ? categories.includes(q.category) || categories.includes(q.category2)
        : q
    );
    setQuestions(filteredQuestions);
    setSelectedOption(filteredQuestions[0].value);
    setQuestionName(filteredQuestions[0].value);

    let mainTitle = "";
    if (filteredQuestions[0].graph_title) { 
      mainTitle =
        localLanguage === "en"
          ? filteredQuestions[0].graph_title
          : filteredQuestions[0].graph_title_fr;
    } else if (filteredQuestions[0].updated_label) {
      mainTitle =
        localLanguage === "en"
          ? filteredQuestions[0].updated_label
          : filteredQuestions[0].updated_label_fr;
    } else {
      mainTitle =
        localLanguage === "en"
          ? filteredQuestions[0].label
          : filteredQuestions[0].label_fr;
    }
    setTitle({
      main: mainTitle,
      sub:
        localLanguage === "en"
          ? filteredQuestions[0].chart_subtitle
          : filteredQuestions[0].chart_subtitle_fr,
    });
  }, [categories]);

  useEffect(() => {
    setLoading(!loading);
    setAllData({});
    if (!questionName) return;

    let notQuebec = "all";
    if (localStorage.getItem("sumups-all-data")) {
      notQuebec = JSON.parse(localStorage.getItem("sumups-all-data"));
    }
    let api = "/api-2-new"
    if(notQuebec){
      if(notQuebec == "quebec"){
        api = "/api-2-quebec";
      }
      if(notQuebec == "ontario"){
        api = "/api-2-ontario"
      }
    } 

    fetchPOST(api, {
      questionname: questionName,
    })
      .then((res) => {
        const mainData = res[questionName];
        if (mainData[0] && !Array.isArray(mainData[0])) {
          res[questionName] = mainData.map((md) => [
            Object.keys(md)[0],
            Object.values(md)[0],
          ]);
        }
        setAllData(res);
        setLoading(loading);
      })
      .catch((e) => {
        setSwalProps({
          show: true,
          title: "Something went wrong",
          text: "Please refresh the page",
        });
        console.log(e.message);
      });
  }, [questionName]);

  const {
    [questionName]: allMainData,
    [`${questionName}_CI`]: main_SubData,
    ["total_weight"]: totalWeightForMulti,
    ...allDemoData
  } = allData || {};

  const isMultiSelectQuestion = useMemo(
    () => MULTI_SELECT_QUESTIONS.includes(questionName),
    [questionName]
  );

  const Option = (props) => {
    const { data, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="cursor-pointer hover:bg-lime-200 focus:bg-lime-200 border-solid border-b-2 border-slate-200"
      >
        {data.order} -{" "}
        {language === "en"
          ? data.updated_label
            ? data.updated_label
            : data.label
          : data.updated_label_fr
            ? data.updated_label_fr
            : data.label_fr}
      </div>
    );
  };

  return (
    <div>
      <SweetAlert2 {...swalProps} />
      <P1>Select a question:</P1>

      <Select
        options={questions}
        value={questions.filter(function (q) {
          return q.value === selectedOption;
        })}
        formatOptionLabel={(option, { context }) => {
          return `${option.order} - ${language === "en"
              ? option.updated_label
                ? option.updated_label
                : option.label
              : option.updated_label_fr
                ? option.updated_label_fr
                : option.label_fr
            }`;
        }}
        components={{ Option }}
        onChange={handleQuestionChange}
      />

      <Spiner visible={loading && questionName !== ""} />

      <ChartBoard
        allMainData={allMainData}
        mainSubData={main_SubData}
        allDemoData={allDemoData}
        totalWeight={totalWeightForMulti}
        title={title}
        multi={isMultiSelectQuestion}
        questionName={questionName}
      />
    </div>
  );
}

export default QuestionDashboard;

// const Tabs = styled.div`
//   width: 80%;
//   margin: 0 auto;
//   max-width: 996px;
//   display: flex;
// `;

// const TabSelected = styled.p`
//   padding: 4px 15px;
//   font-size: 20px;
//   color: white;
//   border: 1px solid white;
//   background-color: #6658fb;
//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
//   margin-right: 1px;
//   cursor: pointer;
// `;

// const Tab = styled.p`
//   padding: 4px 15px;
//   font-size: 20px;
//   color: #6658fb;
//   border: 1px solid #c6c0ff;
//   background-color: white;
//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
//   cursor: pointer;
// `;

// const Container = styled.div`
//   width: 80%;
//   margin: 0 auto;
//   max-width: 996px;
//   background-color: #a9a0ff;
//   padding: 20px;
//   border-radius: 5px;
// `;

// const Label = styled.p`
//   font-size: 15px;
//   color: white;
// `;

const P1 = styled.p`
  font-size: 28px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SelectStyled = styled.select`
  width: 100%;
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px;
  }
`;
