import { Outlet } from "react-router-dom";
import TabbedNavLink from "../components/TabbedNavLink";

function MainBoard() {
  let language = localStorage.getItem("sumups-lang") || "en";

  return (
    <div>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500  border-gray-200 dark:border-gray-700 dark:text-gray-400 buttons-container">
        <li className="mr-2">
          <TabbedNavLink to="/all-questions">
            {language === "en" ? "All Questions" : "Toutes les questions"}
          </TabbedNavLink>
        </li>

        <li className="mr-2">
          <TabbedNavLink to="/those-with-primary-care">
            {language === "en"
              ? "Those with a family doctor or NP"
              : "Ceux qui ont un médecin de famille ou une infirmière praticienne"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/those-without-primary-care">
            {language === "en"
              ? "Those without a family doctor or NP"
              : "Ceux qui n'ont pas de médecin de famille ou d'infirmière praticienne"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/those-looking-for-primary-care">
            {language === "en"
              ? "Those looking for a family doctor or NP"
              : "Ceux qui recherchent un médecin de famille ou un médecin généraliste"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/what-is-important-in-primary-care">
            {language === "en"
              ? "What is important when it comes to primary care"
              : "Ce qui est important en matière de soins primaires"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/walkin-clinics">
            {language === "en"
              ? "In-person and virtual walk-in clinics"
              : "Cliniques sans rendez-vous en personne et virtuelles"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/virtual-care-team-based-care">
            {language === "en"
              ? "Virtual care and team-based care"
              : "Soins virtuels et soins en équipe"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/access-to-medical-information">
            {language === "en"
              ? "Access to medical information"
              : "Accès à l'information médicale"}
          </TabbedNavLink>
        </li>
        <li className="mr-2">
          <TabbedNavLink to="/reimagining-care">
            {language === "en" ? "Reimagining care" : "Réimaginer les soins"}
          </TabbedNavLink>
        </li>

        <li className="mr-2">
          <TabbedNavLink to="/demographics-weighted">
            {language === "en"
              ? "Who responded (Weighted Data)"
              : "Qui ont répondu (données pondérées)"}
          </TabbedNavLink>
        </li>

        <li className="mr-2">
          <TabbedNavLink to="/demographics-unweighted">
            {language === "en"
              ? "Who responded (Unweighted Data)"
              : "Qui ont répondu (données non pondérées)"}
          </TabbedNavLink>
        </li>
      </ul>
      <Outlet />
    </div>
  );
}
export default MainBoard;
