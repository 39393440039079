module.exports = [
  {
    order: 1,
    label:
      "Do you currently have a family doctor or nurse practitioner that you can talk to when you need care or advice about your health? N= 9279",
    label_fr:
      "Avez-vous actuellement un médecin de famille ou une infirmière praticienne à qui vous pouvez parler lorsque vous avez besoin de soins ou de conseils sur votre santé? N = 9279",
    status: "Functional",
    value: "Q2",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 2,
    label: "Is the person you usually talk to a...? N= 7467",
    label_fr:
      "La personne à qui vous parlez habituellement est-elle ...? N = 7467",
    chart_subtitle:
      "Percentage of people with family doctor or nurse practitioner",
    chart_subtitle_fr:
      "Pourcentage de personnes avec médecin de famille ou infirmière praticienne",
    status: "Functional",
    value: "Q3",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 3,
    label:
      "If your [QID2-ChoiceGroup-SelectedChoices] is away, can you get care from another family doctor or nurse practitioner from the same practice?",
    label_fr:
      "Si votre [QID2-ChoiceGroup-SélectuéChoices] est absent, pouvez-vous obtenir des soins d'un autre médecin de famille ou d'une infirmière praticienne de la même pratique?",
    updated_label:
      "If your family doctor or nurse practitioner is away, can you get care from another family doctor or nurse practitioner from the same practice? N= 7463",
    updated_label_fr:
      "Si votre médecin de famille ou votre infirmière praticienne est absent, pouvez-vous obtenir des soins d'un autre médecin de famille ou d'une infirmière praticienne de la même pratique? N = 7463",
    status: "Functional",
    value: "Q7",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 4,
    label:
      "Who else works in the same practice as your [QID2-ChoiceGroup-SelectedChoices]? Please select all that apply. For definitions of these health professions, click here. - Selected Choice",
    label_fr:
      "Qui d'autre travaille dans la même pratique que vos [QID2-Choicegroup-SélectuesChoices]? Veuillez sélectionner tout ceux qui s'appliquent. Pour les définitions de ces professions de santé, cliquez ici. - Choix sélectionné",
    updated_label:
      "Who else works in the same practice as your family doctor or nurse practitioner? N= 7443 For definitions of these health professions, click here. - Selected Choice",
    updated_label_fr:
      "Qui d'autre travaille dans la même pratique que votre médecin de famille ou votre infirmière praticienne? N = 7443 Pour les définitions de ces professions de santé, cliquez ici. - Choix sélectionné",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options",
    status: "Functional",
    value: "Q8",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 5,
    label:
      "Is your [QID2-ChoiceGroup-SelectedChoices] or someone in their practice available to help with urgent issues at the following times and days? Please select all that apply.",
    label_fr:
      "Votre [QID2-ChoiceGroup-Sélectué est-il] ou quelqu'un dans sa pratique disponible pour aider à des problèmes urgents aux heures et aux jours suivants? Veuillez sélectionner tout ceux qui s'appliquent.",
    updated_label:
      "Is your family doctor or nurse practitioner or someone in their practice available to help with urgent issues at the following times and days? N= 7444",
    updated_label_fr:
      "Votre médecin de famille ou infirmière praticienne ou quelqu'un dans son cabinet est-il disponible pour aider à des problèmes urgents aux heures et aux jours suivants? N = 7444",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options",
    status: "Functional",
    value: "Q9",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 6,
    label:
      "During the last 12 months, did you try to book an appointment with your [QID2-ChoiceGroup-SelectedChoices] or someone in their practice because you urgently needed care?",
    label_fr:
      "Au cours des 12 derniers mois, avez-vous essayé de prendre rendez-vous avec votre [QID2-Choicegroup-SélectuéChoices] ou quelqu'un dans sa pratique parce que vous avez besoin de soins urgents?",
    updated_label:
      "During the last 12 months, did you try to book an appointment with your family doctor or nurse practitioner or someone in their practice because you urgently needed care? N= 7254",
    updated_label_fr:
      "Au cours des 12 derniers mois, avez-vous essayé de prendre rendez-vous avec votre médecin de famille ou votre infirmière praticienne ou quelqu'un dans sa pratique parce que vous aviez besoin de soins urgents? N = 7254",
    status: "Functional",
    value: "Q10",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 7,
    label:
      "How long did it take from when you first tried to book the appointment until you had your appointment (either in person, by phone or video conference)?",
    label_fr:
      "Combien de temps a-t-il pris lorsque vous avez essayé de prendre rendez-vous pour la première fois jusqu'à ce que vous ayez votre rendez-vous (en personne, par téléphone ou en vidéo)?",
    updated_label:
      "How long did it take from when you first tried to book the appointment until you had your appointment? N=2906 ",
    updated_label_fr:
      "Combien de temps a-t-il pris lorsque vous avez essayé de prendre rendez-vous pour la première fois jusqu'à ce que vous ayez votre rendez-vous? N = 2906",
    chart_subtitle:
      "Percentage of people with family doctor or nurse practitioner who tried to book appointment during the last 12 months",
    chart_subtitle_fr:
      "Pourcentage de personnes chez un médecin de famille ou une infirmière praticienne qui a tenté de prendre rendez-vous au cours des 12 derniers mois",
    status: "Functional",
    value: "Q11",
    category: "1-primary-care",
    include: "Yes",
  },
  {
    order: 8,
    label:
      "Do you have a regular health care provider who is not a family doctor or nurse practitioner? By this, we mean one health professional that you talk to when you need care or advice about your health.",
    label_fr:
      "Avez-vous un fournisseur de soins de santé régulier qui n'est pas un médecin de famille ou une infirmière praticienne? Par cela, nous voulons dire un professionnel de la santé à qui vous parlez lorsque vous avez besoin de soins ou de conseils sur votre santé.",
    updated_label:
      "Do you have a regular health care provider who is not a family doctor or nurse practitioner? N= 1652",
    updated_label_fr:
      "Avez-vous un fournisseur de soins de santé régulier qui n'est pas un médecin de famille ou une infirmière praticienne? N = 1652",
    status: "Functional",
    value: "Q12",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 9,
    label: "Is the person you usually talk to a….? - Selected Choice",
    label_fr:
      "La personne à qui vous parlez habituellement est-elle….? - Choix sélectionné",
    updated_label:
      "If you have a regular health care provider, is the person you usually talk to a….? N= 243",
    updated_label_fr:
      "Si vous avez un fournisseur de soins de santé régulier, la personne à laquelle vous parlez habituellement est-elle….? N = 243",
    status: "Functional",
    value: "Q13",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 10,
    label:
      "When was the last time you had a family doctor or nurse practitioner? N= 1723",
    label_fr:
      "À quand remonte la dernière fois que vous avez eu un médecin de famille ou une infirmière praticienne? N = 1723",
    status: "Functional",
    value: "Q14",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 11,
    label:
      "Are you currently trying to find a new family doctor or nurse practitioner? N= 9248",
    label_fr:
      "Essayez-vous actuellement de trouver un nouveau médecin de famille ou une infirmière praticienne? N = 9248",
    chart_subtitle:
      "Percentage of all respondents, with or without family doctor or nurse practitioner",
    chart_subtitle_fr:
      "Pourcentage de tous les répondants, avec ou sans médecin de famille ou infirmière praticienne",
    status: "Functional",
    value: "Q15",
    category: "3-looking-for-family-doctor-or-NP",
    include: "Yes",
  },
  {
    order: 12,
    label:
      "Why are you not trying to find a family doctor or nurse practitioner at this time? Please select all that apply. - Selected Choice",
    label_fr:
      "Pourquoi n'essayez-vous pas de trouver un médecin de famille ou une infirmière praticienne pour le moment? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: Why are you not trying to find a family doctor or nurse practitioner at this time? N= 241",
    updated_label_fr:
      "Sonde: Pourquoi n'essayez-vous pas de trouver un médecin de famille ou une infirmière praticienne pour le moment? N = 241",
    chart_subtitle:
      "Percentage of people with no primary care who are not trying to find a family doctor or nurse practitioner. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes sans soins primaires qui n'essaient pas de trouver un médecin de famille ou une infirmière praticienne. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q16",
    category: "3-looking-for-family-doctor-or-NP",
    include: "Yes",
  },
  {
    order: 13,
    label:
      "Why are you trying to find a family doctor or nurse practitioner at this time? Please select all that apply. - Selected Choice",
    label_fr:
      "Pourquoi essayez-vous de trouver un médecin de famille ou une infirmière praticienne pour le moment? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: Why are you trying to find a family doctor or nurse practitioner at this time? N= 887",
    updated_label_fr:
      "Sonde: Pourquoi essayez-vous de trouver un médecin de famille ou une infirmière praticienne pour le moment? N = 887",
    chart_subtitle:
      "Percentage of people with family doctor or nurse practitioner who are trying to find a new family doctor or nurse practitioner. Percentages can exceed 100% as the question allowed respondents to select multiple options",
    chart_subtitle_fr:
      "Pourcentage de personnes chez un médecin de famille ou une infirmière praticienne qui essaient de trouver un nouveau médecin de famille ou infirmière praticienne. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options",
    status: "Functional",
    value: "Q17",
    category: "3-looking-for-family-doctor-or-NP",
    include: "Yes",
  },
  {
    order: 14,
    label:
      "How long have you been trying to find a family doctor or nurse practitioner? N= 2292",
    label_fr:
      "Depuis combien de temps essayez-vous de trouver un médecin de famille ou une infirmière praticienne? N = 2292",
    chart_subtitle:
      "Percentage of all respondents, with or without family doctor or nurse practitioner",
    chart_subtitle_fr:
      "Pourcentage de tous les répondants, avec ou sans médecin de famille ou infirmière praticienne",
    status: "Functional",
    value: "Q18",
    category: "3-looking-for-family-doctor-or-NP",
    include: "Yes",
  },
  {
    order: 15,
    label:
      "How have you tried to find a family doctor or nurse practitioner? Please select all that apply. - Selected Choice",
    label_fr:
      "Comment avez-vous essayé de trouver un médecin de famille ou une infirmière praticienne? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "How have you tried to find a family doctor or nurse practitioner? N= 2382",
    updated_label_fr:
      "Comment avez-vous essayé de trouver un médecin de famille ou une infirmière praticienne? N = 2382",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q19",
    category: "3-looking-for-family-doctor-or-NP",
    include: "Yes",
  },
  {
    order: 16,
    label:
      "Think about the last time you had a health problem that worried you but was not urgent. Where did you try to get care? Please select all that apply. - Selected Choice",
    label_fr:
      "Pensez à la dernière fois que vous avez eu un problème de santé qui vous inquiétait mais qui n'était pas urgent. Où avez-vous essayé de faire des soins? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Think about the last time you had a health problem that worried you but was not urgent. Where did you try to get care? N= 1728",
    updated_label_fr:
      "Pensez à la dernière fois que vous avez eu un problème de santé qui vous inquiétait mais qui n'était pas urgent. Où avez-vous essayé de faire des soins? N = 1728",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options",
    status: "Functional",
    value: "Q20",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 17,
    label: "Did you have to pay a fee? N= 1658",
    label_fr: "Avez-vous dû payer des frais? N = 1658",
    chart_subtitle:
      "Percentages of people with no primary care who tried to get care last time they had a health issue",
    chart_subtitle_fr:
      "Pourcentages de personnes sans soins primaires qui ont essayé de prendre soin de la dernière fois qu'ils avaient un problème de santé",
    status: "Functional",
    value: "Q21",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 18,
    label:
      "What was the fee for? Please select all that apply. - Selected Choice",
    label_fr:
      "À quoi servaient les frais? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label: "What was the fee for? N= 363",
    updated_label_fr: "À quoi servaient les frais? N = 363",
    chart_subtitle:
      "Percentage of people who tried to get care last time they had a health issue and paid a fee. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes qui ont tenté de se faire attention la dernière fois, ils avaient un problème de santé et ont payé des frais. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q22",
    category: "2-no-primary-care",
    include: "Yes",
  },
  {
    order: 19,
    label:
      "How important is it that every person living in Canada has a relationship with a family doctor, nurse practitioner or team of health care professionals that they can see regularly if they need to? N= 9277",
    label_fr:
      "Dans quelle mesure est-il important que chaque personne vivant au Canada ait une relation avec un médecin de famille, une infirmière praticienne ou une équipe de professionnels de la santé qu'elle peut voir régulièrement si elle en a besoin? N = 9277",
    status: "Functional",
    value: "Q23",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 20,
    label:
      "How important is it that your family doctor, nurse practitioner, or team of health care professionals works close to your home? If you don't have a family doctor or nurse practitioner right now, consider what would be ideal.",
    label_fr:
      "Dans quelle mesure est-il important que votre médecin de famille, votre infirmière praticienne ou votre équipe de professionnels de la santé travaille près de chez vous? Si vous n'avez pas de médecin de famille ou d'infirmière praticienne en ce moment, pensez à ce qui serait idéal.",
    updated_label:
      "How important is it that your family doctor, nurse practitioner, or team of health care professionals works close to your home? N= 9270",
    updated_label_fr:
      "Dans quelle mesure est-il important que votre médecin de famille, votre infirmière praticienne ou votre équipe de professionnels de la santé travaille près de chez vous? N = 9270",
    status: "Functional",
    value: "Q25",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 21,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They make it easy for me to get care during the day",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils me permettent de me procurer facilement pendant la journée",
    updated_label:
      'How important is: "They make it easy for me to get care during the day"? N= 9274',
    updated_label_fr:
      'Quelle est l\'importance: "Ils me permettent de faire soin de la journée"? N = 9274',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They make it easy for me to get care during the day"',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils me permettent de me procurer facilement pendant la journée\"",
    status: "Functional",
    value: "Q29_1",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 22,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They make it easy for me to get care in the evening or weekend",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils me permettent de faire des soins le soir ou le week-end",
    updated_label:
      'How important is: "They make it easy for me to get care in the evening or weekend"? N= 9269',
    updated_label_fr:
      'Quelle est l\'importance: "Ils me permettent de faire des soins le soir ou le week-end"? N = 9269',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They make it easy for me to get care in the evening or weekend"',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils me permettent de faire attention le soir ou le week-end\"",
    status: "Functional",
    value: "Q29_2",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 23,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They are able to provide most of my care",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils sont capables de fournir la plupart de mes soins",
    updated_label:
      'How important is: "They are able to provide most of my care"? N= 9264',
    updated_label_fr:
      'Quelle est l\'importance: "Ils sont en mesure de fournir la plupart de mes soins"? N = 9264',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They are able to provide most of my care."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils sont en mesure de fournir la plupart de mes soins.\"",
    status: "Functional",
    value: "Q29_3",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 24,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They coordinate the care I get from multiple places",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils coordonnent les soins que j'obtiens de plusieurs endroits",
    updated_label:
      'How important is: "They coordinate the care I get from multiple places"? N= 9267',
    updated_label_fr:
      'Quelle est l\'importance: "Ils coordonnent les soins que je reçois de plusieurs endroits"? N = 9267',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They coordinate the care I get from multiple places."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils coordonnent les soins que je reçois de plusieurs endroits.\"",
    status: "Functional",
    value: "Q29_4",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 25,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They know me as a person and consider all the factors that affect my health",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils me connaissent en tant que personne et considèrent tous les facteurs qui affectent ma santé",
    updated_label:
      'How important is: "They know me as a person and consider all the factors that affect my health"? N= 9272',
    updated_label_fr:
      'Quelle est l\'importance: "Ils me connaissent en tant que personne et considèrent tous les facteurs qui affectent ma santé"? N = 9272',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They know me as a person and consider all the factors that affect my health."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils me connaissent en tant que personne et considèrent tous les facteurs qui affectent ma santé.\"",
    status: "Functional",
    value: "Q29_5",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 26,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - They stand up for me",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Ils me défendent",
    updated_label: 'How important is: "They stand up for me"? N= 9261',
    updated_label_fr: 'Quelle est l\'importance: "Ils me défendent"? N = 9261',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "They stand up for me."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Ils me défendent.\"",
    status: "Functional",
    value: "Q29_6",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 27,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - Over time, they help me meet my health goals",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Au fil du temps, ils m'aident à atteindre mes objectifs de santé",
    updated_label:
      'How important is: "Over time, they help me meet my health goals"? N= 9266',
    updated_label_fr:
      "Quelle est l'importance: \"Au fil du temps, ils m'aident à atteindre mes objectifs de santé\"? N = 9266",
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "Over time, they help me meet my health goals."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Au fil du temps, ils m'aident à atteindre mes objectifs de santé.\"",
    status: "Functional",
    value: "Q29_7",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 28,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - The care I get in the practice considers knowledge of my family",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Les soins que je reçois dans la pratique considèrent la connaissance de ma famille",
    updated_label:
      'How important is: "The care I get in the practice considers knowledge of my family"? N= 9262',
    updated_label_fr:
      'Quelle est l\'importance: "Les soins que je reçois dans la pratique considèrent la connaissance de ma famille"? N = 9262',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "The care I get in the practice considers knowlege of my family."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Les soins que je reçois dans la pratique considèrent Knowlege de ma famille.\"",
    status: "Functional",
    value: "Q29_8",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 29,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - The care I get in the practice considers my cultural and racial background ",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Les soins que je reçois dans la pratique considèrent mon expérience culturelle et raciale",
    updated_label:
      'How important is: "The care I get in the practice considers my cultural and racial background"? N= 9265',
    updated_label_fr:
      'Quelle est l\'importance: "Les soins que je reçois dans la pratique considèrent mon expérience culturelle et raciale"? N = 9265',
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "The care I get in the practice considers my cultural and racial background."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Les soins que je reçois dans la pratique considèrent mon parcours culturel et racial.\"",
    status: "Functional",
    value: "Q29_9",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 30,
    label:
      "When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important are each of the following to you? - When I have an urgent issue, someone in the practice is able to help me with advice within 24 hours by phone or in person",
    label_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance de chacun des éléments suivants pour vous? - Quand j'ai un problème urgent, quelqu'un dans la pratique est en mesure de m'aider avec des conseils dans les 24 heures par téléphone ou en personne",
    updated_label:
      'How important is: "When I have an urgent issue, someone in the practice is able to help me with advice within 24 hours by phone or in person"? N= 9272',
    updated_label_fr:
      "Quelle est l'importance: \"Quand j'ai un problème urgent, quelqu'un dans la pratique est en mesure de m'aider avec des conseils dans les 24 heures par téléphone ou en personne\"? N = 9272",
    graph_title:
      'When you think about getting care from a family doctor or nurse practitioner and the practice they work in, how important is the following to you? "When I have an urgent issue, someone in the practice is able to help me with advice within 24 hours by phone or in person."',
    graph_title_fr:
      "Lorsque vous pensez à obtenir des soins d'un médecin de famille ou d'une infirmière praticienne et de la pratique dans laquelle ils travaillent, quelle est l'importance pour vous? \"Quand j'ai un problème urgent, quelqu'un dans la pratique est en mesure de m'aider avec des conseils dans les 24 heures par téléphone ou en personne.\"",
    status: "Functional",
    value: "Q29_10",
    category: "4-what-is-important",
    include: "Yes",
  },
  {
    order: 31,
    label:
      "How many times have you tried to get care at a walk-in clinic in the last 12 months? By “walk-in clinic”, we mean in-person or virtual care that is not part of your regular provider’s clinic.",
    label_fr:
      "Combien de fois avez-vous essayé d'obtenir des soins dans une clinique sans rendez-vous au cours des 12 derniers mois? Par «clinique sans rendez-vous», nous entendons des soins en personne ou virtuels qui ne font pas partie de la clinique de votre fournisseur régulier.",
    updated_label:
      "How many times have you tried to get care at a walk-in clinic in the last 12 months? N= 9263",
    updated_label_fr:
      "Combien de fois avez-vous essayé d'obtenir des soins dans une clinique sans rendez-vous au cours des 12 derniers mois? N = 9263",
    status: "Functional",
    value: "Q31",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 32,
    label:
      "How did your walk-in clinic visit(s) happen? Please select all that apply.",
    label_fr:
      "Comment votre (s) visite (s) de votre clinique sans rendez-vous s'est-elle produite? Veuillez sélectionner tout ceux qui s'appliquent.",
    updated_label: "How did your walk-in clinic visit(s) happen? N= 4088",
    updated_label_fr:
      "Comment votre (s) visite (s) de votre clinique sans rendez-vous s'est-elle produite? N = 4088",
    chart_subtitle:
      "Percentage of people who tried to get care at a walk-in clinic in the last 12 months. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes qui ont tenté de faire attention à une clinique sans rendez-vous au cours des 12 derniers mois. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q32",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 33,
    label:
      "Why did you try to get care at a walk-in clinic? Please select all that apply. - Selected Choice",
    label_fr:
      "Pourquoi avez-vous essayé de faire des soins dans une clinique sans rendez-vous? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label: "Why did you try to get care at a walk-in clinic? N= 4113",
    updated_label_fr:
      "Pourquoi avez-vous essayé de faire des soins dans une clinique sans rendez-vous? N = 4113",
    chart_subtitle:
      "Percentage of people who tried to get care at a walk-in clinic in the last 12 months. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes qui ont tenté de faire attention à une clinique sans rendez-vous au cours des 12 derniers mois. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q33",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 34,
    label: "In general, did the walk-in clinic meet your needs? N= 4106",
    label_fr:
      "En général, la clinique sans rendez-vous a-t-elle répondu à vos besoins? N = 4106",
    chart_subtitle:
      "Percentage of people who tried to get care at a walk-in clinic in the last 12 months",
    chart_subtitle_fr:
      "Pourcentage de personnes qui ont essayé de faire attention à une clinique sans rendez-vous au cours des 12 derniers mois",
    status: "Functional",
    value: "Q35",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 35,
    label:
      "In some parts of Canada, there are new virtual services where you can quickly and easily connect with a doctor by phone, video, or online chat at a day and time that is convenient to you. However, with these new services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.  How willing would you be to use this type of service if…? - The service was owned by a for-profit company",
    label_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter rapidement et facilement avec un médecin par téléphone, vidéo ou chat en ligne à un jour et à une heure qui vous convient. Cependant, avec ces nouveaux services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de planifier un rendez-vous de suivi en personne. Dans quelle mesure seriez-vous disposé à utiliser ce type de service si…? - Le service appartenait à une entreprise à but lucratif",
    updated_label:
      "How willing would you be to use new virtual services if the service was owned by a for-profit company? N= 9270",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à utiliser de nouveaux services virtuels si le service appartenait à une entreprise à but lucratif? N = 9270",
    chart_subtitle:
      "In some parts of Canada, there are new virtual services where you can connect with a doctor by phone, video, or online chat at a time that works for you. With these services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.",
    chart_subtitle_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter avec un médecin par téléphone, vidéo ou chat en ligne à un moment qui fonctionne pour vous. Avec ces services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de prendre un rendez-vous de suivi en personne.",
    status: "Functional",
    value: "Q36_1",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 36,
    label:
      "In some parts of Canada, there are new virtual services where you can quickly and easily connect with a doctor by phone, video, or online chat at a day and time that is convenient to you. However, with these new services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.  How willing would you be to use this type of service if…? - The company that ran the service received payments from or was owned by a pharmaceutical company",
    label_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter rapidement et facilement avec un médecin par téléphone, vidéo ou chat en ligne à un jour et à une heure qui vous convient. Cependant, avec ces nouveaux services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de planifier un rendez-vous de suivi en personne. Dans quelle mesure seriez-vous disposé à utiliser ce type de service si…? - L'entreprise qui dirigeait le service a reçu des paiements ou appartenait à une société pharmaceutique",
    updated_label:
      "How willing would you be to use new virtual services if the company that ran the service received payments from or was owned by a pharmaceutical company? N= 9263",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposée à utiliser de nouveaux services virtuels si la société qui dirigeait le service recevait des paiements ou appartenait à une société pharmaceutique? N = 9263",
    chart_subtitle:
      "In some parts of Canada, there are new virtual services where you can connect with a doctor by phone, video, or online chat at a time that works for you. With these services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.",
    chart_subtitle_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter avec un médecin par téléphone, vidéo ou chat en ligne à un moment qui fonctionne pour vous. Avec ces services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de prendre un rendez-vous de suivi en personne.",
    status: "Functional",
    value: "Q36_2",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 37,
    label:
      "In some parts of Canada, there are new virtual services where you can quickly and easily connect with a doctor by phone, video, or online chat at a day and time that is convenient to you. However, with these new services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.  How willing would you be to use this type of service if…? - The service charged you for things you could get for free if you saw your regular doctor or nurse practitioner",
    label_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter rapidement et facilement avec un médecin par téléphone, vidéo ou chat en ligne à un jour et à une heure qui vous convient. Cependant, avec ces nouveaux services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de planifier un rendez-vous de suivi en personne. Dans quelle mesure seriez-vous disposé à utiliser ce type de service si…? - Le service vous a facturé pour des choses que vous pourriez obtenir gratuitement si vous voyiez votre médecin ou votre infirmière praticienne régulière",
    updated_label:
      "How willing would you be to use new virtual services if the service charged you for things you could get for free if you saw your regular doctor or nurse practitioner? N= 9265",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposée à utiliser de nouveaux services virtuels si le service vous facturait pour des choses que vous pourriez obtenir gratuitement si vous voyiez votre médecin ou votre infirmière praticienne régulière? N = 9265",
    chart_subtitle:
      "In some parts of Canada, there are new virtual services where you can connect with a doctor by phone, video, or online chat at a time that works for you. With these services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.",
    chart_subtitle_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter avec un médecin par téléphone, vidéo ou chat en ligne à un moment qui fonctionne pour vous. Avec ces services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de prendre un rendez-vous de suivi en personne.",
    status: "Functional",
    value: "Q36_3",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 38,
    label:
      "In some parts of Canada, there are new virtual services where you can quickly and easily connect with a doctor by phone, video, or online chat at a day and time that is convenient to you. However, with these new services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.  How willing would you be to use this type of service if…? - The company that ran the service sold your health data to pharmaceutical or insurance companies after removing information that could identify you",
    label_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter rapidement et facilement avec un médecin par téléphone, vidéo ou chat en ligne à un jour et à une heure qui vous convient. Cependant, avec ces nouveaux services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de planifier un rendez-vous de suivi en personne. Dans quelle mesure seriez-vous disposé à utiliser ce type de service si…? - L'entreprise qui a dirigé le service a vendu vos données de santé aux compagnies pharmaceutiques ou d'assurance après avoir supprimé des informations qui pourraient vous identifier",
    updated_label:
      "How willing would you be to use new virtual services if the company that ran the service sold your health data to pharmaceutical or insurance companies after removing identifying information? N= 9255",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à utiliser de nouveaux services virtuels si la société qui dirigeait le service vendait vos données de santé aux compagnies pharmaceutiques ou d'assurance après avoir supprimé les informations d'identification? N = 9255",
    chart_subtitle:
      "In some parts of Canada, there are new virtual services where you can connect with a doctor by phone, video, or online chat at a time that works for you. With these services, you may not know the doctor, the doctor may not have access to your health records, and the doctor may not be able to schedule a follow-up appointment in person.",
    chart_subtitle_fr:
      "Dans certaines parties du Canada, il existe de nouveaux services virtuels où vous pouvez vous connecter avec un médecin par téléphone, vidéo ou chat en ligne à un moment qui fonctionne pour vous. Avec ces services, vous ne connaissez peut-être pas le médecin, le médecin peut ne pas avoir accès à vos dossiers de santé et le médecin peut ne pas être en mesure de prendre un rendez-vous de suivi en personne.",
    status: "Functional",
    value: "Q36_4",
    category: "5-walk-in-clinics",
    include: "Yes",
  },
  {
    order: 39,
    label:
      "During the last 12 months, in what ways have you communicated with your [QID2-ChoiceGroup-SelectedChoices]? Please select all that apply.",
    label_fr:
      "Au cours des 12 derniers mois, de quelles manières avez-vous communiqué avec votre [QID2-Choicegroup-SélectuéChoices]? Veuillez sélectionner tout ceux qui s'appliquent.",
    updated_label:
      "During the last 12 months, in what ways have you communicated with your family doctor or nurse practitioner? N= 7464",
    updated_label_fr:
      "Au cours des 12 derniers mois, quelles manières avez-vous communiqué avec votre médecin de famille ou votre infirmière praticienne? N = 7464",
    chart_subtitle:
      "Percentage of people with family doctor or nurse practitioner. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes atteintes de médecin de famille ou d'infirmière praticienne. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q123",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 40,
    label:
      "Think about how you would like to get care from a family doctor or nurse practitioner. Which of the following are most important to you? Please select all that apply.",
    label_fr:
      "Réfléchissez à la façon dont vous souhaitez obtenir des soins d'un médecin de famille ou d'une infirmière praticienne. Lequel des éléments suivants est le plus important pour vous? Veuillez sélectionner tout ceux qui s'appliquent.",
    updated_label:
      "Think about how you would like to get care from a family doctor or nurse practitioner. Which of the following are most important to you? N= 9273",
    updated_label_fr:
      "Réfléchissez à la façon dont vous souhaitez obtenir des soins d'un médecin de famille ou d'une infirmière praticienne. Lequel des éléments suivants est le plus important pour vous? N = 9273",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q175",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 41,
    label:
      "Why are phone appointments not as important to you? Please select all that apply. - Selected Choice",
    label_fr:
      "Pourquoi les rendez-vous téléphoniques ne sont-ils pas aussi importants pour vous? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: Why are phone appointments not as important to you? N= 3059",
    updated_label_fr:
      "Sonde: Pourquoi les rendez-vous téléphoniques ne sont-ils pas aussi importants pour vous? N = 3059",
    graph_title:
      'Probe question for people who did not select phone appointments as "important": Why are phone appointments not as important to you?',
    graph_title_fr:
      'Question de sonde pour les personnes qui n\'ont pas sélectionné les rendez-vous téléphoniques comme "importantes": pourquoi les rendez-vous téléphoniques ne sont-ils pas aussi importants pour vous?',
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q125",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 42,
    label:
      "What would make phone appointments more comfortable for you? Please select all that apply. - Selected Choice",
    label_fr:
      "Qu'est-ce qui rendrait les rendez-vous téléphoniques plus confortables pour vous? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: What would make phone appointments more comfortable for you? N= 2730",
    updated_label_fr:
      "Sonde: Qu'est-ce qui rendrait les rendez-vous téléphoniques plus confortables pour vous? N = 2730",
    graph_title:
      'Probe question for people who did not select phone appointments as "important": What would make phone appointments more comfortable for you?',
    graph_title_fr:
      "Question de sonde pour les personnes qui n'ont pas sélectionné les rendez-vous téléphoniques comme \"importantes\": qu'est-ce qui rendrait les rendez-vous téléphoniques plus confortables pour vous?",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q126",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 43,
    label:
      "Why are video appointments not as important to you? Please select all that apply. - Selected Choice",
    label_fr:
      "Pourquoi les rendez-vous vidéo ne sont-ils pas aussi importants pour vous? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: Why are video appointments not as important to you? N= 5584",
    updated_label_fr:
      "Sonde: Pourquoi les rendez-vous vidéo ne sont-ils pas aussi importants pour vous? N = 5584",
    graph_title:
      'Probe question for people who did not select video appointments as "important": Why are video appointments not as important to you?',
    graph_title_fr:
      'Question de sonde pour les personnes qui n\'ont pas sélectionné les rendez-vous vidéo comme "importantes": pourquoi les rendez-vous vidéo ne sont-ils pas aussi importants pour vous?',
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q127",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 44,
    label:
      "What would make video appointments more comfortable for you? Please select all that apply. - Selected Choice",
    label_fr:
      "Qu'est-ce qui rendrait les rendez-vous vidéo plus confortables pour vous? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Probe: What would make video appointments more comfortable for you? N= 2599",
    updated_label_fr:
      "Sonde: Qu'est-ce qui rendrait les rendez-vous vidéo plus confortables pour vous? N = 2599",
    graph_title:
      'Probe question for people who did not select video appointments as "important": What would make video appointments more comfortable for you?',
    graph_title_fr:
      "Question de sonde pour les personnes qui n'ont pas sélectionné les rendez-vous vidéo comme \"importantes\": qu'est-ce qui rendrait les rendez-vous vidéo plus confortables pour vous?",
    chart_subtitle:
      "Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q128",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 45,
    label:
      "Some family doctors and nurse practitioners work with other health professionals as part of a team. In a team model, you can usually see these other health professionals at no extra cost. Think about your own care. Which health professionals would be most important to have as part of the team? Please select all that apply. - Selected Choice",
    label_fr:
      "Certains médecins de famille et infirmières praticiennes travaillent avec d'autres professionnels de la santé dans le cadre d'une équipe. Dans un modèle d'équipe, vous pouvez généralement voir ces autres professionnels de la santé sans frais supplémentaires.Pensez à vos propres soins. Quels professionnels de la santé seraient le plus important d'avoir dans le cadre de l'équipe? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "Some family doctors and nurse practitioners work with other health professionals as part of a team. Think about your own care. Which health professionals would be most important to have as part of the team? N= 9269",
    updated_label_fr:
      "Certains médecins de famille et infirmières praticiennes travaillent avec d'autres professionnels de la santé dans le cadre d'une équipe. Pensez à vos propres soins. Quels professionnels de la santé seraient le plus important d'avoir dans le cadre de l'équipe? N = 9269",
    chart_subtitle:
      "In a team model, you can usually see these other health professionals at no extra cost. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Dans un modèle d'équipe, vous pouvez généralement voir ces autres professionnels de la santé sans frais supplémentaires. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q131",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 46,
    label:
      "How comfortable would you be getting support from another member of the team, if your family doctor or nurse practitioner recommended it? N= 9267",
    label_fr:
      "Dans quelle mesure seriez-vous à l'aise obtenu le soutien d'un autre membre de l'équipe, si votre médecin de famille ou votre infirmière praticienne le recommandait? N = 9267",
    status: "Functional",
    value: "Q133",
    category: "6-virtual-care-and-teams",
    include: "Yes",
  },
  {
    order: 47,
    label:
      "Have you ever used an app or website to see your medical information (for example, test results, medical images like X-rays, or doctor’s notes)?",
    label_fr:
      "Avez-vous déjà utilisé une application ou un site Web pour voir vos informations médicales (par exemple, les résultats des tests, les images médicales comme les rayons X ou les notes du médecin)?",
    updated_label:
      "Have you ever used an app or website to see your medical information? N= 9264",
    updated_label_fr:
      "Avez-vous déjà utilisé une application ou un site Web pour voir vos informations médicales? N = 9264",
    status: "Functional",
    value: "Q134",
    category: "7-health-records",
    include: "Yes",
  },
  {
    order: 48,
    label:
      "What type of information have you looked at online? Please select all that apply. - Selected Choice",
    label_fr:
      "Quel type d'informations avez-vous regardé en ligne? Veuillez sélectionner tout ceux qui s'appliquent. - Choix sélectionné",
    updated_label:
      "What type of information have you looked at online? N= 5473",
    updated_label_fr:
      "Quel type d'informations avez-vous regardé en ligne? N = 5473",
    chart_subtitle:
      "Percentage of people who have used an app or website to see their medical information. Percentages can exceed 100% as the question allowed respondents to select multiple options.",
    chart_subtitle_fr:
      "Pourcentage de personnes qui ont utilisé une application ou un site Web pour voir leurs informations médicales. Les pourcentages peuvent dépasser 100% car la question a permis aux répondants de sélectionner plusieurs options.",
    status: "Functional",
    value: "Q135",
    category: "7-health-records",
    include: "Yes",
  },
  {
    order: 49,
    label:
      "How important is it to you that you are able to look at your personal health information online? N= 9277",
    label_fr:
      "Quelle est l'importance pour vous que vous puissiez consulter vos informations de santé personnelles en ligne? N = 9277",
    status: "Functional",
    value: "Q136",
    category: "7-health-records",
    include: "Yes",
  },
  {
    order: 50,
    label:
      "How important is it to you to have one personal health record that all health professionals working in the province could see and use when they are providing care to you? N= 9276",
    label_fr:
      "Dans quelle mesure est-il important pour vous d'avoir un dossier de santé personnel que tous les professionnels de la santé travaillant dans la province pourraient voir et utiliser lorsqu'ils vous procurent des soins? N = 9276",
    status: "Functional",
    value: "Q137",
    category: "7-health-records",
    include: "Yes",
  },
  {
    order: 51,
    label:
      "Do you think teams of family doctors and nurse practitioners in Canada should have to accept as a patient any person who lives in the neighbourhood near their office? This is how family doctor care is organized in some other countries, and how the public school system in Canada operates. Similar to the public school system, this means that the size of the team would be a match to the care needs of the neighbourhood.",
    label_fr:
      "Pensez-vous que les équipes de médecins de famille et d'infirmières au Canada devraient avoir à accepter en tant que patient, toute personne qui vit dans le quartier près de leur bureau? C'est ainsi que les soins de famille sont organisés dans certains autres pays et comment le système scolaire public au Canada fonctionne. Semblable au système scolaire public, cela signifie que la taille de l'équipe correspondrait aux besoins de soins du quartier.",
    updated_label:
      "Do you think teams of family doctors and nurse practitioners in Canada should have to accept as a patient any person who lives in the neighbourhood near their office? N= 9274",
    updated_label_fr:
      "Pensez-vous que les équipes de médecins de famille et d'infirmières au Canada devraient avoir à accepter en tant que patient, toute personne qui vit dans le quartier près de leur bureau? N = 9274",
    status: "Functional",
    value: "Q138",
    category: "8-future",
    include: "Yes",
  },
  {
    order: 52,
    label:
      "Do you think every person living in Canada should be guaranteed a family doctor, nurse practitioner, or health team in their neighbourhood, even if it means patients will be encouraged to change providers once they move to a different neighbourhood? N= 9276",
    label_fr:
      "Pensez-vous que chaque personne vivant au Canada devrait être garantie d'un médecin de famille, d'une infirmière praticienne ou d'une équipe de santé dans son quartier, même si cela signifie que les patients seront encouragés à changer de prestataires une fois qu'il déménage dans un quartier différent? N = 9276",
    status: "Functional",
    value: "Q139",
    category: "8-future",
    include: "Yes",
  },
  {
    order: 53,
    label:
      "A system in which every person in Canada has timely, regular access to a family doctor or nurse practitioner may have its trade-offs. How willing would you be to accept the situations below? - Seeing the same nurse practitioner consistently for most things except when the nurse practitioner feels a doctor is needed. The nurse practitioner would have access to your health records",
    label_fr:
      "Un système dans lequel chaque personne au Canada a un accès régulier et régulier à un médecin de famille ou à une infirmière praticienne peut avoir ses compromis. À quel point seriez-vous disposé à accepter les situations ci-dessous? - Voir la même infirmière praticienne de manière cohérente pour la plupart des choses, sauf lorsque l'infirmière praticienne estime qu'un médecin est nécessaire. L'infirmière praticienne aurait accès à vos dossiers de santé",
    updated_label:
      "How willing would you be to see the same nurse practitioner consistently for most things except when they feel a doctor is needed? The nurse practitioner would have access to your health records. N= 9273",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à voir la même infirmière praticienne de manière cohérente pour la plupart des choses, sauf lorsqu'elle estime qu'un médecin est nécessaire? L'infirmière praticienne aurait accès à vos dossiers de santé. N = 9273",
    status: "Functional",
    value: "Q140_1",
    category: "8-future",
    include: "Yes",
  },
  {
    order: 54,
    label:
      "A system in which every person in Canada has timely, regular access to a family doctor or nurse practitioner may have its trade-offs. How willing would you be to accept the situations below? - Seeing any family doctor or nurse practitioner in the practice. You may not see the same person consistently but everyone you see would have access to your health records",
    label_fr:
      "Un système dans lequel chaque personne au Canada a un accès régulier et régulier à un médecin de famille ou à une infirmière praticienne peut avoir ses compromis. À quel point seriez-vous disposé à accepter les situations ci-dessous? - Voir tout médecin de famille ou infirmière praticienne dans la pratique. Vous ne verrez peut-être pas la même personne de manière cohérente, mais tous ceux que vous voyez auraient accès à vos dossiers de santé",
    updated_label:
      "How willing would you be to see any family doctor or nurse practitioner in the practice? You may not see the same person consistently but everyone you see would have access to your health records. N= 9272",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à voir un médecin de famille ou une infirmière praticienne dans la pratique? Vous ne verrez peut-être pas la même personne de manière cohérente, mais tous ceux que vous voyez auraient accès à vos dossiers de santé. N = 9272",
    status: "Functional",
    value: "Q140_2",
    category: "8-future",
    include: "Yes",
  },
  {
    order: 55,
    label:
      "A system in which every person in Canada has timely, regular access to a family doctor or nurse practitioner may have its trade-offs. How willing would you be to accept the situations below? - Having to choose from a list of family doctors or nurse practitioners close to your home, instead of being able to choose anyone in the province",
    label_fr:
      "Un système dans lequel chaque personne au Canada a un accès régulier et régulier à un médecin de famille ou à une infirmière praticienne peut avoir ses compromis. À quel point seriez-vous disposé à accepter les situations ci-dessous? - Devoir choisir parmi une liste de médecins de famille ou d'infirmières praticiennes à proximité de chez vous, au lieu de pouvoir choisir n'importe qui dans la province",
    updated_label:
      "How willing would you be to choose from a list of family doctors or nurse practitioners close to your home, instead of being able to choose anyone in the province? N= 9274",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à choisir parmi une liste de médecins de famille ou d'infirmières praticiennes près de chez vous, au lieu de pouvoir choisir quelqu'un dans la province? N = 9274",
    status: "Functional",
    value: "Q140_3",
    category: "8-future",
    include: "Yes",
  },
  {
    order: 56,
    label:
      "A system in which every person in Canada has timely, regular access to a family doctor or nurse practitioner may have its trade-offs. How willing would you be to accept the situations below? - Seeing a family doctor or nurse practitioner at any practice in your region. You may not know the provider but, with your permission, they can access your health records",
    label_fr:
      "Un système dans lequel chaque personne au Canada a un accès régulier et régulier à un médecin de famille ou à une infirmière praticienne peut avoir ses compromis. À quel point seriez-vous disposé à accepter les situations ci-dessous? - Voir un médecin de famille ou une infirmière praticienne dans toute pratique de votre région. Vous ne connaissez peut-être pas le fournisseur mais, avec votre permission, ils peuvent accéder à vos dossiers de santé",
    updated_label:
      "How willing would you be to see a family doctor or nurse practitioner at any practice in your region? You may not know the provider but, with your permission, they can access your health records. N= 9269",
    updated_label_fr:
      "Dans quelle mesure seriez-vous disposé à voir un médecin de famille ou une infirmière praticienne dans n'importe quelle pratique de votre région? Vous ne connaissez peut-être pas le fournisseur mais, avec votre permission, il peut accéder à vos dossiers de santé. N = 9269",
    status: "Functional",
    value: "Q140_4",
    category: "8-future",
    include: "Yes",
  },
];
